<template>
    <v-container>
        <MasterDetail
            :cols="cols"
            :opts="opts"
            :customResource="customResource"
        >
        </MasterDetail>
    </v-container>
</template>

<script>
export default {
    // props: {},
    name: 'Orçamento detalhado',
    components: {
            MasterDetail: () => import('@/components/master-detail.vue'),
        },
    // mixins: [],
    // directives: {},
    computed: {
        cols() {
            return [
                {
                    key: 'rubrica_id',
                    name: 'Rubrica',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'descricao_id',
                    name: 'Descrição',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'descricao_id',
                    name: 'Finalidade',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'valor',
                    name: 'Valor',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'parcela',
                    name: 'Parcela',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'contrapartida',
                    name: 'Contapartida',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
            ]
        },
        customResource() {
            return this.apiResource(`v1/captacao/orcamentos/${this.$route.params.orcamentoId}`)
        }
    },
    data() {
        return {
            opts: {}
        }
    },
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
    methods: {
    },
        // watch: {},
    }
</script>

<style>

</style>